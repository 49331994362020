import { useModal } from "@/features/modal/ModalStackManager";
import { theme } from "@/styles/theme";
import { setToLocaleString } from "@/utils/unitMark";
import styled from "@emotion/styled";
import { Table, Text } from "@mantine/core";
import { ItemsGet200ResponseRowsInnerOperationsInnerOperationBomsInner } from "@sizlcorp/sizl-api-document/dist/models";
import { ItemsDetailForm } from "../../ItemsDetailForm";

const TableContainer = styled(Table)``;

const Thead = styled.thead`
  display: flex;
  width: 100%;
  align-items: flex-start;
  align-self: stretch;
`;

const Th = styled.th<{ width?: number }>`
  display: flex;
  width: ${(props) => (props.width ? `${props.width}%` : "auto")};
  padding: 7px 10px;
  justify-content: center;
  align-items: center;
  gap: 10px;
`;

const Tr = styled.tr`
  display: flex;
  width: 100%;
  align-items: center;
`;

const Tbody = styled.tbody`
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: flex-start;
  align-self: stretch;
`;

const Td = styled.td<{ width?: number }>`
  display: flex;
  width: ${(props) => (props.width ? `${props.width}%` : "auto")};
  height: 100%;
  padding: 7px 10px;
  align-items: flex-start;
  gap: 10px;
`;

const TextNumber = styled<any>(Text)`
  width: 100%;
`;

export const OperationBomRow = (params: {
  operationBoms: ItemsGet200ResponseRowsInnerOperationsInnerOperationBomsInner[];
}) => {
  const { operationBoms } = params;
  const { openModal } = useModal();

  return (
    <TableContainer>
      <Thead>
        <Tr>
          <Th width={20}>
            <Text>시퀀스</Text>
          </Th>
          <Th width={50}>원부자재</Th>
          <Th width={30}>수량</Th>
        </Tr>
      </Thead>
      <Tbody>
        {operationBoms &&
          operationBoms.map(
            (
              operationBom: ItemsGet200ResponseRowsInnerOperationsInnerOperationBomsInner,
              i: number
            ) =>
              operationBom.seq &&
              operationBom.itemCode &&
              operationBom.quantity && (
                <Tr key={i}>
                  <Td width={20}>
                    <TextNumber ta="right">{operationBom.seq}</TextNumber>
                  </Td>
                  <Td width={50}>
                    <Link onClick={() => openModal(
                      <ItemsDetailForm itemCode={operationBom.itemCode as string} />,
                      null,
                      "품목 상세"
                    )}>
                      {operationBom?.item?.name ?? operationBom?.itemName ?? '-'}({operationBom.itemCode})
                      {/* <IconLink /> */}
                      {/* <IconLinkPlus /> */}
                    </Link>
                    {/* <NavLink
                    label={operationBom.itemCode}
                    icon={<IconLink size="1rem" stroke={1.5} />}
                  /> */}
                  </Td>
                  <Td width={30}>
                    <TextNumber ta="right">
                      {setToLocaleString(operationBom.quantity ?? "")} {operationBom.item?.unit_text}
                    </TextNumber>
                  </Td>
                </Tr>
              )
          )}
      </Tbody>
    </TableContainer >
  );
};

const Link = styled.a`
  color: ${theme.colors?.blue?.[7]};
  text-decoration: none;
  font-size: 14px;
  cursor: pointer;
`;
